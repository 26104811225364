import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-reserve',
  templateUrl: './reserve.component.html',
  styleUrls: ['./reserve.component.scss']
})
export class ReserveComponent implements OnInit {

  cities: any[];
  selectedCity: any;
  countries: any[];
  selectedCountry: any;
  date1:any ;
  date2:any ;
  data:any ;
  id:any;

  constructor(private router: Router,
     public translate: TranslationService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    private pagesService: PagesService) { }

  ngOnInit(): void {

    this.spinner.show();
    //catch id from URL
    this.route.params.subscribe(
      (params: Params) => {
        this.id = params['id'];
      }
    );

    //get countries
    this.pagesService.getCountry().subscribe(
      (response: any) => {
        console.log(response)
        this.countries = response.data;
      },
      (error) => {
        console.log(error)
      }
    );

    this.pagesService.getReservationById(this.id).subscribe(
      (response: any) => {
        this.data = response.data;
        console.log("caravan",this.data);
        this.selectedCountry = this.data.countryName;
        this.selectedCity = this.data.cityName;
        this.date1 = this.data.fromDate.slice(0,10);
        this.date2 = this.data?.toDate.slice(0,10);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
      }
    );
  }

  onChangeCountry(){
    console.log(this.selectedCountry);
    this.getCities();
  }

  //get cities
  getCities() {
    this.pagesService.getCity(this.selectedCountry.id).subscribe(
      (response: any) => {
        console.log(response)
        this.cities = response.data;
      },
      (error) => {
        console.log(error);
      }
    )
  }
  onhandle=()=>{
    // this.router.navigate(['../../payMethod'], {relativeTo: this.route});
    this.router.navigate(['../../payMethod/' + this.id], {relativeTo: this.route});
  }

  goToStore=()=>{
    this.router.navigate(['../../store/' + this.id], {relativeTo: this.route});
  }
}
