import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ReserveRoutingModule } from './reserve-routing.module';
import { ReserveComponent } from './reserve.component';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {CalendarModule} from 'primeng/calendar';
import { PagesSharedModule } from '../pagesShared.module';



@NgModule({
  declarations: [ReserveComponent],
  imports: [
    CommonModule,
    ReserveRoutingModule,
    DropdownModule,
    TabViewModule,
    CalendarModule,
    FormsModule,
    PagesSharedModule
  ]
})
export class ReserveModule { }
