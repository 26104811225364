<div class="container">
  <div [ngClass]="{'reserve': translate.is_arabic, 'reserveEn': !translate.is_arabic}">
    <div class="row">
      <div class="header">
        <p class="d-lg-inline d-none"> {{translate.data.myReservations.reserveNumber}}</p>
        <p class="d-lg-none d-inline fw-bold me-3">{{translate.data.myReservations.myReservations}}</p>
      </div>
      <div class="body-r flex-wrap">
        <div class="col-lg-9 col-12" id="edit">
          <p class="main-title d-lg-inline d-none">{{translate.data.myReservations.caravan}}</p>
          <div class="cont-1">
            <div class="con-2">
              <img class="main-img" src="{{sharedService.ipRootImg + data?.image}}"  alt="">
              <!-- <img class="heart-img" src="../../../assets/caravan/Web 1920 – 33/Group 10409.svg" alt=""> -->
            </div>
            <div class="cont-3">
              <div class="cont-3-header">
                <img class="twenty-img" src="../../../assets/caravan/Group 10466.svg" alt="">
                <div style="margin-top: 5px;">
                  <star-rating *ngIf="data?.rate" [value]="data?.rate"
                          [totalstars]="5"
                          checkedcolor="#ed6c37"
                          uncheckedcolor="rgb(224, 224, 224)"
                          size="24px"
                          [readonly]="true">
                  </star-rating>
                </div>
              </div>
              <p class="main-title-cont-3">{{data?.carvanName}}</p>
              <div class="cash">
                <p class="cash-l">{{data?.price}}</p>
                <p> {{translate.data.caravanCard.coin}} / {{translate.data.caravanCard.night}}</p>
              </div>
              <div class="box-container">
                <div>
                  <p>{{translate.data.reservationProfile.seat}} {{data?.seatNo}}</p>
                  <img src="../../../assets/caravan/office-chair.svg" alt="">
                </div>
                <div>
                  <p>{{translate.data.caravanDetail.beds}} {{data?.bedsNo}}</p>
                  <img src="../../../assets/caravan/bed.svg" alt="">
                </div>
              </div>
              <div class="map-title-cont">
                <img src="../../../../assets/Content/location-pin/location-pin.png" />
                <div>
                  <p class="map-title-p1">{{translate.data.myReservations.region}}</p>
                  <p class="map-title-p2">
                    {{data ? data[translate.data.requestdData.carGenCountry] : 'N/A'}} /
                    {{data ? data[translate.data.requestdData.carGenCity] : 'N/A'}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img class="addBtn" (click)="goToStore()" src="../../../assets/profile/button/753345.svg" alt="" style="height: 70px;">
          </div>
        </div>



        <div class="col-lg-3 col-12 ">
          <div class="body">
            <div class="title">
              <!--price-->
              <p>{{data?.pricePerDay}}</p>
              <p> {{translate.data.reservationProfile.price}} </p>
            </div>
            <div class="loc">
              <img src="../../../../assets/Content/location-pin/location-pin.png" />
              <input type="text"
                     [ngModel]="data ? data[this.translate.data.requestdData.carGenCountry] : 'N/A' " class="input" />
          </div>
          <div class="loc">
            <img src="../../../../assets/Content/location-pin/location-pin.png" />
            <input type="text"
                  [ngModel]="data ? data[this.translate.data.requestdData.carGenCity] : 'N/A' " class="input" />
          </div>

            <div class="loc">
              <img src="../../../../assets/Content/calendar/calendar.png" />
              <input type="text" [(ngModel)]="date1" class="input" />

            </div>
            <div class="loc">
            <img src="../../../../assets/Content/calendar/calendar.png" />
            <input type="text" [(ngModel)]="date2" class="input" />
            </div>

            <div class="titles-cont">
              <p>{{translate.data.caravanDetail.addedTax}}</p>
              <div class="title-cont">
                <p class="title-p1">{{data?.taxValue}}</p>
                <p>{{translate.data.caravanCard.coin}}</p>
              </div>
            </div>

            <div class="titles-cont">
              <p>{{translate.data.caravanCard.total}}</p>
              <div class="title-cont">
                <!--totalWithTax-->
                <p class="title-p1">{{data?.total}}</p>
                <p>{{translate.data.caravanCard.coin}}</p>
              </div>
            </div>
            <div class="titles-cont">
              <p>عدد اليالي</p>
              <div class="title-cont">
                <p class="title-p1">{{data?.dayCount}}</p>
              </div>
            </div>
            <br/>
            <div class="titles-cont">
              <a href="https://www.caravan.icitybackend.com/api/Contract/ShowContract?Id={{id}}" target="_blank">
              <p>قراة العقد وشروط التعاقد</p>
            </a>
            </div>
            <br/>
            <div class="titles-cont">
             
                      
              <p><input type="checkbox" #ChkApprove checked="checked"/>
                 أوافق علي شروط التعاقد
                </p>

            </div>

            <button (click)="onhandle()">{{translate.is_arabic ? 'ادفع الأن' : 'Pay now'}}</button>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
